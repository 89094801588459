const icons = {
  respiration: '<g transform="translate(0 0)" width="15.672" height="14.814" viewBox="0 0 15.672 14.814"><g transform="translate(5.999 0)"><path class="a" d="M194.949,18.469c-.932,0-.977-.3-.977-.883V14.421a.735.735,0,0,0-1.469,0v3.166c0,.58-.046.883-.977.883a.124.124,0,0,0-.125.122v1.225a.125.125,0,0,0,.125.122,2.665,2.665,0,0,0,1.711-.5,2.669,2.669,0,0,0,1.712.5.124.124,0,0,0,.125-.122V18.592A.124.124,0,0,0,194.949,18.469Z" transform="translate(-191.4 -13.686)"/></g><g transform="translate(0 1.115)"><g transform="translate(0 0)"><path class="a" d="M6.426,56.2a21.515,21.515,0,0,1-.175-2.351c0-1.969-.216-4.587-2.077-4.587C1.91,49.258,0,55.268,0,59.042c0,.119-.015,2.927.754,3.7a.657.657,0,0,0,.475.213,1.476,1.476,0,0,0,1.011-.421,6.09,6.09,0,0,1,2.335-1.127C6.93,60.73,6.686,58.528,6.426,56.2Z" transform="translate(0 -49.258)"/></g></g><g transform="translate(9.087 1.115)"><g transform="translate(0 0)"><path class="a" d="M296.474,59.044c0-3.775-1.91-9.785-4.173-9.785-1.862,0-2.077,2.618-2.077,4.587a21.583,21.583,0,0,1-.175,2.351c-.259,2.332-.5,4.535,1.85,5.213a6.094,6.094,0,0,1,2.335,1.127,1.474,1.474,0,0,0,1.011.421.657.657,0,0,0,.475-.213C296.489,61.97,296.475,59.162,296.474,59.044Z" transform="translate(-289.888 -49.258)"/></g></g></g>',
  pulse: '<g transform="translate(-6 1)" width="16.666" height="16.049" viewBox="0 0 16.666 16.049"><g transform="translate(5.999 0)"><path class="a" d="M8.334,28.787C4.5,24.685-1.727,28.15.45,33.691q.09.228.187.449H5.152l1.8-4,2.111,5.537.588-1.534H16.03q.1-.221.187-.449C18.394,28.15,12.163,24.685,8.334,28.787Z" transform="translate(0 -26.989)"/><g transform="translate(1.08 7.257)"><g transform="translate(0 0)"><path class="a" d="M45.4,214.568l-1.359,3.5-2.221-5.75L40.8,214.568H36.052a17.183,17.183,0,0,0,7.254,6.539,17.183,17.183,0,0,0,7.253-6.539Z" transform="translate(-36.052 -212.315)"/></g></g></g></g>',
  oxygen: '<g transform="translate(-1 0)" width="17.838" height="17.143" viewBox="0 0 17.838 17.143"><path class="a" d="M9.792,6.374a6.976,6.976,0,1,0,6.976,6.975A6.983,6.983,0,0,0,9.792,6.374Zm0,12.7a5.724,5.724,0,1,1,5.724-5.725A5.73,5.73,0,0,1,9.792,19.072Z" transform="translate(-1.253 -3.182)"/><circle class="a" cx="1.878" cy="1.878" r="1.878" transform="translate(0 1.955)"/><circle class="a" cx="1.436" cy="1.436" r="1.436" transform="translate(13.372 1.955)"/><circle class="a" cx="0.9" cy="0.9" r="0.9" transform="translate(16.039)"/><circle class="a" cx="0.704" cy="0.704" r="0.704" transform="translate(4.694 0.548)"/><path class="a" d="M9.538,13.623a2.761,2.761,0,0,0-2.043.733,2.769,2.769,0,0,0-.734,2.05,3.039,3.039,0,0,0,.37,1.569,2.258,2.258,0,0,0,.966.917,3.453,3.453,0,0,0,1.506.289,3.026,3.026,0,0,0,1.494-.335,2.281,2.281,0,0,0,.917-.938,3.32,3.32,0,0,0,.317-1.547,2.715,2.715,0,0,0-.726-2.019A2.81,2.81,0,0,0,9.538,13.623Zm.84,3.967a1.025,1.025,0,0,1-.829.348,1.021,1.021,0,0,1-.823-.356,1.8,1.8,0,0,1-.3-1.17,1.806,1.806,0,0,1,.3-1.177,1.009,1.009,0,0,1,.807-.357,1.05,1.05,0,0,1,.831.35,1.663,1.663,0,0,1,.307,1.11A2,2,0,0,1,10.378,17.59Z" transform="translate(-3.007 -6.406)"/><path class="a" d="M18.761,19.594c.067-.054.2-.173.4-.312a2.643,2.643,0,0,0,.693-.661,1.157,1.157,0,0,0,.19-.626.979.979,0,0,0-.621-.921,2.2,2.2,0,0,0-.812-.121,1.976,1.976,0,0,0-.783.125.974.974,0,0,0-.437.359,1.574,1.574,0,0,0-.213.651l.99.081a.73.73,0,0,1,.162-.421.46.46,0,0,1,.612,0,.37.37,0,0,1,.12.278.55.55,0,0,1-.121.318,2.908,2.908,0,0,1-.556.489,4.245,4.245,0,0,0-.969.869,6.59,6.59,0,0,0-.309.782h2.708v-.626H18.526C18.615,19.856,18.693,19.649,18.761,19.594Z" transform="translate(-7.605 -7.887)"/></g>',
  blood: '<g transform="translate(2 -1)" width="12.353" height="17.284" viewBox="0 0 12.353 17.284"><path class="a" d="M9.414,4.21C7.988,2,6.542.191,6.528.174a.443.443,0,0,0-.7,0C5.81.191,4.364,2,2.939,4.21.989,7.24,0,9.574,0,11.146a6.007,6.007,0,0,0,1.834,4.525,6.3,6.3,0,0,0,4.342,1.613,6.3,6.3,0,0,0,4.342-1.613,6.007,6.007,0,0,0,1.834-4.525C12.353,9.574,11.364,7.24,9.414,4.21ZM9.3,14.136a.444.444,0,0,1-.3.123.451.451,0,0,1-.354-.176.537.537,0,0,1,.049-.714,2.908,2.908,0,0,0,.862-2.223.468.468,0,1,1,.933,0A3.914,3.914,0,0,1,9.3,14.136Zm0,0"/></g>'
}

export {
  icons
}
