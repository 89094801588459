<template>
  <div class="display-flex flex-column">
    <div class="display-flex justify-between pb1-5">
      <div class="display-flex flex-wrap f1-5">
        <div class="w100p text-violet">
          Name: {{ device.patient.name_first + ' ' + device.device.name_last }}
        </div>
        <div class="w100p text-grey">
          Location: {{ device.machine.ward + ' ' + device.machine.section + ' ' + device.machine.bed}}
        </div>
      </div>
      <div class="w100p text-right">Time Occurred: <span>{{ formatTime() }}</span></div>
    </div>
    <div class="display-flex justify-between align-center">
      <div v-for="data in feed" :key="device.deviceId + data.name" class="display-flex flex-column justify-center align-center">
        <bh-icon :icon="data.icon" class="f2-5 br50p bg-vital-round w4 h4" :class="`text-${data.icon}`" :iconClass="'w4 lh4 block text-center'"/>
        <span class="mt0-5" :class="data.is_alert_triggered ? 'alert' : ''">{{ data.value }}</span>
      </div>
      <div class="display-flex justify-center align-center alert-status">
        {{ status }}
      </div>
    </div>
  </div>
</template>

<script>
import moment from '@/utils/moment'
import { totalAlertsDetails } from '@/services/total-alerts'

export default {
  name: 'bh-total-alerts-card',
  props: {
    device: {
      type: Object
    }
  },
  mounted() {
    if (this.device) {
      const deepCopieAlerts = JSON.parse(JSON.stringify(totalAlertsDetails))
      for (const data of deepCopieAlerts) {
        const { key } = data
        let value = this.device[key]?.value
        if (key === 'blood_pressure') {
          const { blood_pressure_systolic, blood_pressure_diastolic } = this.device
          value = `${blood_pressure_systolic.value}/${blood_pressure_diastolic.value}`
        }
        data.value = value
      }

      this.feed = deepCopieAlerts
    }
  },
  data() {
    return {
      // this data should come as props
      deviceId: '13578',
      status: 'Predicted',
      datetime: moment.formatDate(new Date().toISOString(), 'hh:mm a | Do MMM, YYYY'),
      feed: [],
      loading: false
    }
  },
  methods: {
    formatTime() {
      return moment.formatDate(this.device.datetime * 1000, 'hh:mm a | Do MMM, YYYY')
    }
  }
}
</script>

<style>
  .alert-status {
    border: 1px solid green;
    border-radius: 5px;
    width: 10rem;
    height: 3rem;
  }
</style>
