<template>
  <div class="display-flex justify-around flex-column-mb p1 mr6-dk ml6-dk">
    <div class="display-flex flex-column text-left w70p w100p-mb mr1" v-if="showVenn">
      <div class="flex">
        <bh-icon icon="arrow-left" class="mr1 text-violet inline f2 mt0-7" @output.self="goBack"/>
        <h3 class="f2 mt1 mb1">Total Alerts</h3>
      </div>
      <bh-card>
        <bh-total-alerts-chart class="pa1" :combinations="combinations" @combinationClicked="combinationClicked($event)" @dateChanged="dateChanged($event)" />
      </bh-card>
    </div>
    <div class="display-flex flex-column text-left w30p w100p-mb pos-rel">
      <h3 class="f2 mt1 mb1 display-flex">
        Details
        <span v-if="deviceDetails.length">
          for:
          <bh-icon :icon="getIcon(vital)" class="ml1 inline" :class="`text-${getIcon(vital)}`" v-for="(vital, index) in combination.split(':')" :key="index" />
          <span class="ml1">({{deviceDetails.length}})</span>
        </span>
      </h3>
      <bh-card class="container overflow-auto" :class="{'cover-spin' : loading, 'nav-bar-open': !isNavBarClose}">
        <div v-for="(device, index) in deviceDetails" :key="device.deviceId + index" class="display-flex flex-column pv2 card-container pointer" @click="deviceClicked(device)">
          <bh-device-card :device="device" />
        </div>
        <bh-loader v-if="loading" />
        <div class="display-flex justify-center align-center f2 container h80 hauto-mb" v-if="!deviceDetails.length">
          <span v-if="loading"> Loading data for </span>
          <span v-else>No Data for</span>
          <bh-icon :icon="getIcon(vital)" class="ml1 inline" :class="`text-${getIcon(vital)}`" v-for="(vital, index) in combination.split(':')" :key="index" />
        </div>
      </bh-card>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { DeviceAlertCard, DeviceAlertChart } from '@/components/TotalAlerts'
import { updateCombintaions, vitalsMap } from '@/services/total-alerts'

export default {
  name: 'bh-total-alerts',
  components: {
    'bh-device-card': DeviceAlertCard,
    'bh-total-alerts-chart': DeviceAlertChart
  },
  computed: {
    ...mapGetters(['isNavBarClose'])
  },
  sockets: {
    'ven-chart-data': function({ chart, vitals }) {
      this.combinations = updateCombintaions(chart)
      this.deviceDetails = vitals
      this.showVenn = true
      this.setLoader(false)
    },
    'combination-vital-response': function(data) {
      this.deviceDetails = data
      this.loading = false
    },
    'combination-chart-response': function(data) {
      if (!data) data = {}
      this.combinations = updateCombintaions(data)
      this.loading = false
    }
  },
  beforeMount() {
    if (this.$socket?.disconnected) this.$socket.connect()
  },
  mounted() {
    this.setLoader(true)
    this.setSingleProperty(['enableSearch', true])
    if (this.$socket.connected) {
      this.$socket.emit('join', { room: this.roomName })
    }
  },
  data() {
    return {
      roomName: 'ven-chart',
      combination: 'BP:OX:PU:RR',
      combinations: {},
      deviceDetails: [],
      showVenn: false,
      loading: false,
      selectedDates: {}
    }
  },
  methods: {
    ...mapActions(['setLoader', 'setSingleProperty']),
    deviceClicked(deviceData) {
      this.$router.push({ name: 'DeviceDetail', params: { id: deviceData?.deviceId } })
    },
    combinationClicked(combination) {
      // get combination values
      this.combination = combination
      // Fallback
      // const data = await TotalAlertService.getVennDetails(`combination=${combination}`)
      // this.deviceDetails = data
      this.loading = true
      const request = { combination }
      if (this.selectedDates && this.selectedDates.from) {
        request.from = this.selectedDates.from.toISOString()
      }
      if (this.selectedDates && this.selectedDates.to) {
        request.to = this.selectedDates.to.toISOString()
      }
      this.$socket.emit('combination-vital', request)
    },
    dateChanged(selectedDates) {
      this.selectedDates = selectedDates
      this.loading = true
      this.$socket.emit('combination-vital', { combination: this.combination, from: selectedDates.from.toISOString(), to: selectedDates.to.toISOString() })
      this.$socket.emit('combination-chart', { from: selectedDates.from.toISOString(), to: selectedDates.to.toISOString() })
    },
    goBack() {
      this.$router.go(-1)
    },
    getIcon (vital) {
      return vitalsMap[vital]
    }
  },
  beforeDestroy() {
    this.setSingleProperty(['enableSearch', false])
    if (this.$socket.connected) this.$socket.disconnect()
  }
}
</script>

<style scoped>
  .card-container {
    border-bottom: 1px solid #eff1fc;
  }

  .cover-spin {
    background-color: #eff1fc !important;
  }

  @media (min-width: 1024px) {
    .container {
       max-height: 82rem;
       max-height: calc(((((100vw - 18rem) * 0.7) - 4rem) * 0.6628) + 16rem);
       height: calc(((((100vw - 18rem) * 0.7) - 4rem) * 0.6628) + 16rem);
    }
    .container.nav-bar-open {
       max-height: 82rem;
       max-height: calc(((((100vw - 32rem) * 0.7) - 4rem) * 0.6628) + 16rem);
       height: calc(((((100vw - 32rem) * 0.7) - 4rem) * 0.6628) + 16rem);
    }
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    .container {
      max-height: 52rem;
      max-height: calc(((((100vw - 18rem) * 0.7) - 4rem) * 0.6628) + 16rem);
      height: calc(((((100vw - 18rem) * 0.7) - 4rem) * 0.6628) + 16rem);
    }
    .container.nav-bar-open {
      max-height: 52rem;
      max-height: calc(((((100vw - 32rem) * 0.7) - 4rem) * 0.6628) + 16rem);
      height: calc(((((100vw - 32rem) * 0.7) - 4rem) * 0.6628) + 16rem);
    }
  }
</style>
