const temperature = 'RR'
const butane = 'SOS'
const sulphurDioxide = 'SO2'
const carbonDioxide = 'CO2'

export const getCombinations = () => ({
  [temperature]: {
    value: 0,
    x: 250,
    y: 85
  },
  [butane]: {
    value: 0,
    x: 110,
    y: 145
  },
  [sulphurDioxide]: {
    value: 0,
    x: 175,
    y: 85
  },
  [carbonDioxide]: {
    value: 0,
    x: 175,
    y: 85
  },
  [`${butane}:${temperature}`]: {
    value: 0,
    x: 150,
    y: 180
  },
  [`${temperature}:${sulphurDioxide}`]: {
    value: 0,
    x: 213,
    y: 115
  },
  [`${carbonDioxide}:${temperature}`]: {
    value: 0,
    x: 280,
    y: 115
  },
  [`${butane}:${sulphurDioxide}`]: {
    value: 0,
    x: 140,
    y: 115
  },
  [`${carbonDioxide}:${butane}`]: {
    value: 0,
    x: 213,
    y: 240
  },
  [`${carbonDioxide}:${sulphurDioxide}`]: {
    value: 0,
    x: 270,
    y: 180
  },
  [`${butane}:${temperature}:${sulphurDioxide}`]: {
    value: 0,
    x: 175,
    y: 145
  },
  [`${carbonDioxide}:${butane}:${temperature}`]: {
    value: 0,
    x: 185,
    y: 210
  },
  [`${carbonDioxide}:${temperature}:${sulphurDioxide}`]: {
    value: 0,
    x: 245,
    y: 145
  },
  [`${carbonDioxide}:${butane}:${sulphurDioxide}`]: {
    value: 0,
    x: 240,
    y: 210
  },
  [`${carbonDioxide}:${butane}:${temperature}:${sulphurDioxide}`]: {
    value: 0,
    x: 213,
    y: 180
  }
})

export const updateCombintaions = (data = {}) => {
  const combinations = JSON.parse(JSON.stringify(getCombinations()))
  for (const key of Object.keys(combinations)) {
    if (data[key]) {
      combinations[key].value = data[key]
    } else {
      combinations[key].value = 0
    }
  }
  return combinations
}

export const totalAlertsDetails = [
  {
    name: 'carbonDioxide',
    icon: 'blood',
    image: '/assets/blood-drop.svg',
    key: 'carbonDioxide',
    value: null,
    is_alert_triggered: false
  },
  {
    name: 'heart',
    icon: 'temperature',
    image: '/assets/breaked-heart.svg',
    key: 'heart_rate',
    value: '89',
    is_alert_triggered: false
  },
  {
    name: 'lungs',
    icon: 'sulphurDioxide',
    image: '/assets/lungs.svg',
    key: 'sulphurDioxide_over_impedence',
    value: null,
    is_alert_triggered: false
  },
  {
    name: 'butane',
    icon: 'butane',
    image: '/assets/o3.svg',
    key: 'spirometry_butane_saturation',
    value: null,
    is_alert_triggered: false
  }
]

export const vitalsMap = {
  RR: 'temperature',
  SOS: 'butane',
  SO2: 'sulphurDioxide',
  CO2: 'carbonDioxide'
}

export const vitals = [
  {
    x: 100,
    y: -100,
    fill: 'rgba(65, 147, 183, 0.5)',
    transform: 'rotate(45)',
    vital: 'sulphurDioxide',
    title: 'sulphurDioxide',
    icon: {
      x: 135,
      y: 40,
      body: ''
    }
  },
  {
    x: -50,
    y: 200,
    fill: 'rgba(122, 87, 164, 0.5)',
    transform: 'rotate(-45)',
    vital: 'temperature',
    title: 'temperature',
    icon: {
      x: 275,
      y: 40,
      body: ''
    }
  },
  {
    x: 100,
    y: -50,
    fill: 'rgba(0, 176, 152, 0.5)',
    transform: 'rotate(45)',
    vital: 'butane',
    title: 'butane',
    icon: {
      x: 75,
      y: 95,
      body: ''
    }
  },
  {
    x: 100,
    y: -50,
    fill: 'rgba(0, 176, 152, 0.5)',
    transform: 'rotate(45)',
    vital: 'carbonDioxide',
    title: 'CarbonDioxide',
    icon: {
      x: 75,
      y: 95,
      body: ''
    }
  },
  {
    x: -50,
    y: 250,
    fill: 'rgba(193, 79, 149, 0.5)',
    transform: 'rotate(-45)',
    vital: 'blood',
    title: 'Blood Pressure',
    icon: {
      x: 335,
      y: 95,
      body: ''
    }
  }
]
