<template>
  <div class='flex flex-column'>
    <div class="display-flex w100p justify-between flex-wrap-mb">
      <div class="display-flex justify-center w50p-mb  mb2" v-for="(vital, index) in vitals" :key="index">
        <div class="h100p w6 mr1" :style="{ 'background-color': vital.fill }"></div>
        <span class="f1-5">{{ vital.title }}</span>
      </div>
    </div>
    <svg id='total-alerts-chart'></svg>
    <div id='total-alerts-slider-container'></div>
    <div class="f1-5 mt1 text-center"> {{formatDate(selectedDates.from)}} - {{formatDate(selectedDates.to)}}</div>
  </div>
</template>

<script>
import * as d3 from 'd3'
import { icons } from '@/services/icons'
import { vitalsMap, vitals } from '@/services/total-alerts'
import { createD3RangeSlider } from '@/services/d3-range-slider'
import moment from '@/utils/moment'

export default {
  name: 'bh-total-alerts-chart',
  props: {
    combinations: {
      type: Object
    }
  },
  data() {
    return {
      alertsData: [],
      selectedDates: {
        from: moment.dateSubtract(new Date(), 10080, 'minutes'),
        to: new Date()
      },
      vitals,
      svg: d3.select('#total-alerts-chart')
    }
  },
  watch: {
    combinations: function(newVal, oldVal) {
      this.clearChart()
      this.drawChart()
    }
  },
  mounted() {
    this.setSelectedDates()
    this.drawChart()
    this.renderSlider()
  },
  methods: {
    setSelectedDates(from = 10080, to = 0) {
      this.selectedDates = {
        from: moment.dateSubtract(new Date(), from, 'minutes'),
        to: moment.dateSubtract(new Date(), to, 'minutes')
      }
    },
    formatDate(date, format = 'DD MMM hh:mm a') {
      return moment.formatDate(date, format)
    },
    clearChart() {
      this.svg.selectAll('*').remove()
    },
    drawChart() {
      this.svg = d3.select('#total-alerts-chart')
      /* For the drop shadow filter... */
      const defs = this.svg.append('defs')

      const filter = defs.append('filter')
        .attr('id', 'dropshadow')

      filter.append('feGaussianBlur')
        .attr('in', 'SourceAlpha')
        .attr('stdDeviation', 1)
        .attr('result', 'blur')

      filter.append('feOffset')
        .attr('in', 'blur')
        .attr('dx', 0)
        .attr('dy', 1)
        .attr('result', 'offsetBlur')

      const feMerge = filter.append('feMerge')

      feMerge.append('feMergeNode')
        .attr('in', 'offsetBlur')
      feMerge.append('feMergeNode')
        .attr('in', 'SourceGraphic')

      this.svg.attr('viewBox', [0, 0, 430, 285])
      // Draw boundries of vitals
      this.svg.selectAll('rect').data(this.vitals).enter().append('rect')
        .attr('x', vital => vital.x)
        .attr('y', vital => vital.y)
        .attr('width', 250)
        .attr('height', 100)
        .attr('rx', 50)
        .attr('ry', 50)
        .attr('fill', vital => vital.fill)
        .attr('transform', vital => vital.transform)
      // Dray values in vitals,
      this.svg.selectAll('text').data(Object.keys(this.combinations)).enter().append('text')
        .attr('x', combination => this.combinations[combination].x)
        .attr('y', combination => this.combinations[combination].y)
        .attr('font-size', '12px')
        .attr('text-anchor', 'middle')
        .attr('class', 'pointer')
        .attr('dominant-baseline', 'middle')
        .text(combination => this.combinations[combination].value)
        .on('click', (combination) => { this.$emit('combinationClicked', combination) })
        .on('mouseover', (combination) => {
          const tooltip = this.svg.append('g').attr('class', 'tooltip')
          tooltip.append('rect')
            .attr('class', 'tooltip-box')
            .attr('x', this.combinations[combination].x + 15)
            .attr('y', this.combinations[combination].y - 12)
            .attr('rx', 7)
            .attr('ry', 7)
            .attr('fill', '#ffffff')
            .attr('width', (combination.slice(':').length + 1) * 8)
            .attr('height', 24)
            .attr('filter', 'url(#dropshadow)')
          tooltip
            .append('g')
            .attr('transform', vital => `translate(${this.combinations[combination].x + 18} ${this.combinations[combination].y - 8})`)
            .selectAll('g.tooltip-icons')
            .data(combination.split(':'))
            .enter()
            .append('g')
            .attr('class', vital => 'tooltip-icons ' + vitalsMap[vital])
            .attr('transform', (vital, index) => `translate(${index * 24} 0)`)
            .html((vital, index) => icons[vitalsMap[vital]])
        })
        .on('mouseout', (combination) => {
          this.svg.selectAll('g.tooltip').remove()
        })

      const svgIcons = this.svg.selectAll('g.icons').data(this.vitals).enter().append('g')
        .attr('transform', vital => `translate(${vital.icon.x} ${vital.icon.y})`)

      svgIcons.append('circle').attr('cx', 8).attr('cy', 8).attr('r', 12).style('fill', '#ffffff')

      svgIcons.append('g').attr('class', vital => 'icons ' + vital.vital).html(vital => icons[vital.vital])
    },
    renderSlider() {
      let debounceTimer
      const slider = createD3RangeSlider(d3, 10080, 0, '#total-alerts-slider-container')
      slider.range(10080, 0)
      slider.onChange((newRange) => {
        this.setSelectedDates(newRange.begin, newRange.end)
        if (debounceTimer) {
          clearTimeout(debounceTimer)
        }
        debounceTimer = setTimeout(() => {
          this.$emit('dateChanged', this.selectedDates)
          debounceTimer = undefined
        }, 1000)
      })
    }
  }
}
</script>

<style>
.respiration .a {
  fill:#5792b3;
}

.pulse .a {
  fill:#7857b3;
}

.oxygen .a {
  fill:#21b7a3;
}

.blood .a {
  fill:#b35792;
}

#total-alerts-slider-container {
  position: relative;
  height: 4rem;
  background-color: #eeeef5;
}
</style>
